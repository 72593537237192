import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>NOSSOS SERVIÇOS</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    {d.title && (
                       <p style={{textAlign: "justify"}}>{d.title}</p>
                    )}
                    <p style={{textAlign: "justify"}}>{d.text}</p>
                    {d.text2 && (
                       <p style={{textAlign: "justify"}}>{d.text2}</p>
                    )}
                    {d.Why && (
                      <ul>
                        {d.Why.map((d, i) => (
                          <li style={{textAlign: "justify",}} key={`${d}-${i}`}>{d}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
