import React from "react";
import { TypeAnimation } from 'react-type-animation';

export const Header = (props) => {
  const openLink = (link) => {
    let url = "https://wa.me/+55"+link+"?text=Olá";
    window.open(url, '_blank', 'noreferrer');
  }
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                {props.data ? (
                  <>
                  <span style={{ fontSize: '0.8em', display: 'inline-block', color: "var(--main-font-color)" }} >
                    {props.data.title}
                  </span>
                    <TypeAnimation
                      sequence={[
                        props.data.paragraph, // Types 'One'
                        10000, // Waits 1s
                      ]}
                      omitDeletionAnimation={true}
                      wrapper="p"
                      cursor={false}
                      repeat={Infinity}
                      style={{color: "var(--main-font-color)", marginTop: 30 }}
                    />
                    <p style={{color: "#FFF", marginBottom: 30, marginTop: 100, textTransform: "none", textAlign: "justify", paddingLeft: 10, paddingRight: 10 }}>{props.data.text}</p>
                    <button
                      className="btn btn-custom btn-lg page-scroll"
                      onClick={() => openLink(props.data.phone)}
                      >
                      Agende uma consulta
                    </button>{" "}
                    <p style={{color: "#FFF", marginBottom: 30, textTransform: "none" }}>{props.data.bottom}</p>
                  </>
                ) : "Loading" }
                  
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
