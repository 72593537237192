import React from "react";

export const Features = (props) => {
  const openLink = (link) => {
    let url = "https://wa.me/+55"+link+"?text=Olá";
    window.open(url, '_blank', 'noreferrer');
  }
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Sobre Direito Familiar </h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={d+i} className="col-md-10 col-md-offset-1" style={{marginBottom: 30}}>
                  <p>{d.text}</p>
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
      <button
        className="btn btn-custom btn-lg page-scroll"
        onClick={() => openLink(props.data.phone)}
        >
        Agende uma consulta
      </button>{" "}
    </div>
  );
};
