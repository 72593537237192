import React from "react";

export const Logo = ({largeImage }) => {
  return (
    <div className="portfolio-item">
      <div className="hover-bg">
          <img src={largeImage} className="logo" />
      </div>
    </div>
  );
};
